import {
  IRegion,
  IHarbour,
} from "Components/Templates/Bookings/Components/BookingsLocationPicker/BookingsLocationPicker.model";
import toast from "react-hot-toast";
import { API_URLS } from "shared/Constants/api.const";
import { APIHarbourResponse } from "shared/model/API/api.harbour.model";
import http from "../http";
import {
  APIClubRegion,
  APIClubsResponse,
  APIRegionCreateRequest,
  APIRegionCreateResponse,
  APIRegionsResponse,
  TransformedClubRegion,
} from "shared/model/API/api.region.model";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";

export const getClubsByCurrentUser = async (clubId?: string) => {

  const urlPathname = clubId ? `${API_URLS.GET_ALL_CLUBS}?clubId=${clubId}` : API_URLS.GET_ALL_CLUBS;
  const url = new URL(urlPathname);
  const clubsResponse = await http.get<APIClubsResponse>(url.toString());
  const clubs = clubsResponse?.clubs;
  return clubs;
};

export const getHarboursByRegion = async (
  regionIds: string
): Promise<IHarbour[]> => {
  const { harbors } = await http
    .get<APIHarbourResponse>(`${API_URLS.GET_HARBOURS_BY_REGION}${regionIds}`)
    .then((data) => data);
  return harbors.map((harbour) => ({
    id: harbour.id,
    name: harbour.harborName,
    locationId: harbour.regionId,
    locationName: harbour.regionName,
    clubId: harbour.clubId,
    address: harbour.address,
    emailId: harbour.email,
    imgageUrl: harbour.harborImage,
    phoneNumber: harbour.phoneNo,
  }));
};

export const getRegionsByClub = async (
  clubId: string
): Promise<APIClubRegion[]> => {
  const { regions } = await http
    .get<APIRegionsResponse>(`${API_URLS.GET_REGIONS_BY_CLUB}${clubId}`)
    .then((data) => data);
  return regions;
};

export const getRegionsWithHarboursByClub = async (
  clubId: string
): Promise<IRegion[]> => {
  const locations: IRegion[] = [];
  try {
    const regions = await getRegionsByClub(clubId);
    locations.push(
      ...(await Promise.all(
        regions.map(async (region) => {
          try {
            const harbours = await getHarboursByRegion(region.regionId);
            const location: IRegion = {
              id: region.regionId,
              name: region.regionName,
              clubId: region.clubId,
              harbours,
            };
            return location;
          } catch (error) {
            return {
              id: region.regionId,
              name: region.regionName,
              clubId: region.clubId,
              harbours: [],
            };
          }
        })
      ))
    );
  } catch (error) {

    toast.error("Could not fetch Location for this club");
  }
  return locations;
};

export const getRegionsForBookings = async (clubId?: string): Promise<
  TransformedClubRegion[]
> => {
  try {
    const clubs = await getClubsByCurrentUser(clubId);
    const regions: TransformedClubRegion[] = clubs
      .map((club) =>
        club.regions
          // .filter((region) => region?.harbors?.length > 0)
          .map((region) => ({
            regionId: region.regionId,
            regionName: region.regionName,
            clubId: club.clubId,
            harbors: region.harbors.map((harbour) => ({
              clubId: club.clubId,
              name: harbour.harborName,
              id: harbour.harborId,
            })),
          }))
      )
      .flat();
    return regions;

  } catch (error: any) {
    toast.error(error?.message || "Could not fetch Location for this club");
    return [];
  }
};

export const getRegions = async (): Promise<IRegion[]> => {
  try {
    const clubs = await getClubsByCurrentUser();
    const regions = (
      await Promise.all(
        clubs.map((club) => getRegionsWithHarboursByClub(club.clubId))
      )
    ).flat();
    return regions.filter((region) => region.harbours.length > 0);
  } catch (error: any) {
    toast.error(error?.message || "Could not fetch Location for this club");
    return [];
  }
};

export const getHarbours = async (): Promise<IHarbour[]> => {
  const regions = await getRegions();
  return regions.map((region) => region.harbours).flat();
};

export const createRegion = async (region: APIRegionCreateRequest) => {
  return http.post<APIRegionCreateResponse>(API_URLS.CREATE_REGION, region);
};

export const updateRegion = async (
  region: APIRegionCreateRequest,
  regionId: string
) => {
  return http.put<APIRegionCreateResponse>(
    API_URLS.UPDATE_REGION.replace(":regionId", regionId),
    region
  );
};


export const uploadImage = async (imageFile: any) => {
  const folderName = S3_FOLDER_NAME.CLUB_LOGO;
  const formData = new FormData();
  formData.append("files", imageFile);
  let res;

  res = await http.postFormData(
    `${API_URLS.UPLOAD_IMAGE}${folderName}`,
    formData
  );

  return res;
};


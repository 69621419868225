import { Suspense, lazy } from "react";

const MoxseaUsers = lazy(() => import("./MoxseaUsers"));

const MoxseaUsersRoutes = [
  {
    path: "/moxsea-users",
    element: (
      <Suspense>
        <MoxseaUsers />
      </Suspense>
    ),
  },
];

export default MoxseaUsersRoutes;

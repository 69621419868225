import {
  Button,
  Container,
  Divider,
  Flex,
  Image,
  NumberInput,
  TextInput,
  Select,
  Title,
  FileButton,
  Group,
  Box,
  Loader,
  Autocomplete,
  Text,
} from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";
import BoatImagePlaceholder from "../../../../../assets/images/icons/boat-image-placeholder.svg";
import { useState, useEffect } from "react";
import { FileToDataUrl } from "shared/utils/file";
import {
  CenterTabContextProvider,
  useCenterTabContext,
} from "./CenterTab.context";
import {
  CountryList,
  Currencies,
  Timezones_List,
  Hours,
  Vat,
  CLUB_TYPE,
  FUEL_TYPE,
} from "shared/Constants/general.const";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";
import { useCenterContext } from "../../Center.context";
import { useForm, isEmail, isNotEmpty } from "@mantine/form";
import toast from "react-hot-toast";
import moment from "moment";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

const libraries = ["places"];

const mapContainerStyle = {
  width: "300px",
  height: "150px",
};

const center = {
  lat: 19.076, // Default location
  lng: 72.8777,
};

const Search = ({ setSelected, setAddress, address, readOnly }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 37.7749, lng: () => -122.4194 }, // Default to San Francisco
      radius: 200 * 1000, // Radius in meters (200km)
    },
  });

  const handleSelect = async (address) => {
    setAddress(address);
    setValue(address, false); // Set the value of the input field without triggering suggestions
    clearSuggestions(); // Clear the suggestions

    try {
      const results = await getGeocode({ address }); // Get the geocode data
      const { lat, lng } = await getLatLng(results[0]); // Extract latitude and longitude
      setSelected({ lat, lng }); // Set selected coordinates in the parent component
    } catch (error) {
      console.log("Error: ", error); // Log errors if any
    }
  };

  console.log("jsdfhgkjsdfg", address);

  useEffect(() => {
    if (address) {
      handleSelect(address);
    }
  }, [address]);

  return (
    <Flex direction={"column"} w={"45%"}>
      <Text>Address</Text>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          readOnly={readOnly}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          style={{ borderRadius: "12px", width: "100%" }}
          placeholder="Enter an address"
        />
        <ComboboxPopover>
          {status === "OK" && (
            <ComboboxList>
              {data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} zIndex={1} />
              ))}
            </ComboboxList>
          )}
        </ComboboxPopover>
      </Combobox>
    </Flex>
  );
};

const CenterTabContainer = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // api key
    libraries,
  });

  const [selected, setSelected] = useState(null);

  const { harbourData, setIsButtonDisabled } = useCenterContext();
  const {
    harbourDetailsEdit,
    harbourDetails,
    setHarborId,
    uploadImage,
    loading,
  } = useCenterTabContext();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  useEffect(() => {
    setHarborId(harbourData?.harborId);
    setFileUrl(null);
  }, [harbourData?.harborId]);

  const isHarbourDataEmpty =
    !harbourDetails?.harborId || harbourDetails?.harborId.length === 0;

  const form = useForm({
    initialValues: {
      phoneNo: "",
      emergencyNo: "",
      email: "",
      // address: "",
      harbor: "",
      pictureFile: null,
      name: "",
      clubType: "",
      companyName: "",
      vatNumber: "",
      address1: "",
      zipCode: "",
      city: "",
      country: null,
      highStartDate: null,
      highEndDate: null,
      lowStartDate: null,
      lowEndDate: null,
      highSeasonAM: null,
      lowSeasonAM: null,
      timeZone: null,
      highSeasonPM: null,
      lowSeasonPM: null,
      currency: null,
      fuelPrice: "",
      fuelType: null,
      vat: null,
      electricPrice: "",
      engineHourPrice: "",
    },
    validate: {
      phoneNo: isNotEmpty("Phone number is required"),
      emergencyNo: isNotEmpty("Emergency number is required"),
      email: isEmail("Invalid email"),
      // address: isNotEmpty("Address is required"),
      harbor: isNotEmpty("Harbor is required"),
      name: isNotEmpty("Name is required"),
      clubType: isNotEmpty("Club type is required"),
      companyName: isNotEmpty("Company name is required"),
      vatNumber: isNotEmpty("Vat number is required"),
      address1: isNotEmpty("Address is required"),
      zipCode: isNotEmpty("Zip code is required"),
      city: isNotEmpty("City is required"),
      country: isNotEmpty("Country is required"),
      highSeasonAM: isNotEmpty("High season AM is required"),
      lowSeasonAM: isNotEmpty("Low season AM is required"),
      timeZone: isNotEmpty("Time zone is required"),
      highSeasonPM: isNotEmpty("High season PM is required"),
      lowSeasonPM: isNotEmpty("Low season PM is required"),
      currency: isNotEmpty("Currency is required"),
      fuelPrice: isNotEmpty("Fuel price is required"),
      vat: isNotEmpty("Vat is required"),
      fuelType: isNotEmpty("Fuel type is required"),
      electricPrice: isNotEmpty("Electric price is required"),
      engineHourPrice: isNotEmpty("Engine hour price is required"),
    },
  });

  useEffect(() => {
    if (harbourDetails) {
      form.setValues({
        phoneNo: harbourDetails?.phoneNo,
        emergencyNo: harbourDetails?.emergencyNumber,
        email: harbourDetails?.email,
        // address: harbourDetails?.mapAddress,
        harbor: harbourDetails?.harborPresentation,
        pictureFile: harbourDetails?.pictureFile || BoatImagePlaceholder,
        name: harbourDetails?.harborName,
        clubType: harbourDetails?.clubType,
        companyName: harbourDetails?.fiscalCompanyName,
        vatNumber: harbourDetails?.vatNo,
        address1: harbourDetails?.companyAddress,
        zipCode: harbourDetails?.zip,
        city: harbourDetails?.city,
        country: harbourDetails?.country,
        highStartDate: harbourDetails?.highSeasonStart,
        highEndDate: harbourDetails?.highSeasonEnd,
        lowStartDate: harbourDetails?.lowSeasonStart,
        lowEndDate: harbourDetails?.lowSeasonEnd,
        highSeasonAM: String(harbourDetails?.highSeasonAMHours),
        lowSeasonAM: String(harbourDetails?.lowSeasonAMHours),
        timeZone: String(harbourDetails?.timeZone),
        fuelPrice: String(harbourDetails?.fuelPrice),
        fuelType: harbourDetails?.fuelUnits,
        vat: String(harbourDetails?.vatLocal),
        currency: harbourDetails?.currency,
        highSeasonPM: String(harbourDetails?.highSeasonPMHours),
        lowSeasonPM: String(harbourDetails?.lowSeasonPMHours),
        electricPrice: harbourDetails?.electricPrice,
        engineHourPrice: harbourDetails?.engineHourPrice,
      });
      setAddress(harbourDetails?.mapAddress);
    }
  }, [harbourDetails]);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { selectedClub } = useClubsContext();

  const newharbourDetails = {
    clubId: selectedClub?.clubId,
    regionId: harbourDetails.regionId,
    pictureFile: form.values.pictureFile,
    harborName: form.values.name,
    clubType: form.values.clubType,
    phoneNo: form.values.phoneNo,
    emergencyNumber: form.values.emergencyNo,
    email: form.values.email,
    mapAddress: address,
    harborPresentation: form.values.harbor,
    fiscalCompanyName: form.values.companyName,
    vatNo: form.values.vatNumber,
    companyAddress: form.values.address1,
    zip: form.values.zipCode,
    city: form.values.city,
    country: form.values.country,
    highSeasonStart: moment(form.values.highStartDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    highSeasonEnd: moment(form.values.highEndDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    lowSeasonStart: moment(form.values.lowStartDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    lowSeasonEnd: moment(form.values.lowEndDate).format("YYYY-MM-DD HH:mm:ss"),
    highSeasonAMHours: Number(form.values.highSeasonAM),
    highSeasonPMHours: Number(form.values.highSeasonPM),
    lowSeasonAMHours: Number(form.values.lowSeasonAM),
    lowSeasonPMHours: Number(form.values.lowSeasonPM),
    timeZone: Number(form.values.timeZone),
    currency: form.values.currency,
    fuelPrice: Number(form.values.fuelPrice),
    fuelUnits: form.values.fuelType,
    vatLocal: Number(form.values.vat),
    electricPrice: Number(form.values.electricPrice),
    engineHourPrice: Number(form.values.engineHourPrice),
  };

  if (isLoading) {
    return (
      <Container
        w="100%"
        h="100%"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Title order={3}>Loading...</Title>
      </Container>
    );
  }

  console.log("newharbourDetails", address);

  const handleSaveClick = async () => {
    if (file) {
      uploadImage(file).then(async (res) => {
        const harborLogo = res?.uploadedFileUrls[0];
        newharbourDetails.pictureFile = harborLogo;
        const response = await harbourDetailsEdit(
          newharbourDetails,
          harbourData.harborId
        );
        if (response.hasErrors === false) {
          toast.success("Changes saved successfully");
          setIsEditing(false);
          setIsLoading(false);
          setIsButtonDisabled(false);
        } else {
          toast.error("Harbor Name Already Exists", { appearance: "error" });
        }
      });
    } else {
      const response = await harbourDetailsEdit(
        newharbourDetails,
        harbourData.harborId
      );
      if (response.hasErrors === false) {
        toast.success("Changes saved successfully");
        setIsEditing(false);
        setIsLoading(false);
        setIsButtonDisabled(false);
      } else {
        toast.error("Harbor Name Already Exists", { appearance: "error" });
      }
    }
  };

  const handleCancelEdit = () => {
    setIsButtonDisabled(false);
    setIsEditing(false);
    form.setValues({
      phoneNo: harbourDetails?.phoneNo,
      emergencyNo: harbourDetails?.emergencyNumber,
      email: harbourDetails?.email,
      // address: harbourDetails?.mapAddress,
      harbor: harbourDetails?.harborPresentation,
      pictureFile: harbourDetails?.pictureFile,
      name: harbourDetails?.harborName,
      clubType: harbourDetails?.clubType,
      companyName: harbourDetails?.fiscalCompanyName,
      vatNumber: harbourDetails?.vatNo,
      address1: harbourDetails?.companyAddress,
      zipCode: harbourDetails?.zip,
      city: harbourDetails?.city,
      country: harbourDetails?.country,
      highStartDate: harbourDetails?.highSeasonStart,
      highEndDate: harbourDetails?.highSeasonEnd,
      lowStartDate: harbourDetails?.lowSeasonStart,
      lowEndDate: harbourDetails?.lowSeasonEnd,
      highSeasonAM: String(harbourDetails?.highSeasonAMHours),
      lowSeasonAM: String(harbourDetails?.lowSeasonAMHours),
      timeZone: String(harbourDetails?.timeZone),
      fuelPrice: String(harbourDetails?.fuelPrice),
      fuelType: harbourDetails?.fuelUnits,
      vat: String(harbourDetails?.vatLocal),
      currency: harbourDetails?.currency,
      highSeasonPM: String(harbourDetails?.highSeasonPMHours),
      lowSeasonPM: String(harbourDetails?.lowSeasonPMHours),
      electricPrice: harbourDetails?.electricPrice,
    });
    setFileUrl(null);
    setAddress(harbourDetails?.mapAddress);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <Flex>
      {loading ? (
        <Flex justify={"center"} w={"100%"} align={"center"} h={"70vh"}>
          <Loader size={"xl"} />
        </Flex>
      ) : (
        <Box
          w={"100%"}
          component="form"
          onSubmit={form.onSubmit(() => {
            if (form.isValid()) {
              handleSaveClick();
            }
          })}
        >
          <Flex justify="space-between" mb={8}>
            <Title order={3} ml={"lg"}>
              Center- {form.values.name}
            </Title>
            <Flex>
              {isEditing ? (
                <>
                  <Button
                    size="sm"
                    onClick={handleCancelEdit}
                    style={{ backgroundColor: "#d0d0d0", color: "#005779" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="primary"
                    style={{
                      marginLeft: 12,
                      backgroundColor: "#d0d0d0",
                      color: "#005779",
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    setIsButtonDisabled(true);
                    setIsEditing(true);
                  }}
                  disabled={isHarbourDataEmpty}
                >
                  Edit
                </Button>
              )}
            </Flex>
          </Flex>

          <Flex justify={"space-around"}>
            <Flex justify="center" align={"center"} direction={"column"}>
              {fileUrl ? (
                <Image
                  radius="md"
                  w={200}
                  h={150}
                  src={fileUrl}
                  fit="contain"
                />
              ) : (
                <Image
                  radius="lg"
                  w={200}
                  h={115}
                  src={form.values.pictureFile}
                  fit="contain"
                />
              )}
              {isEditing && (
                <Group justify="center" mt={8}>
                  <FileButton
                    onChange={setFile}
                    accept="image/png, image/jpeg, image/jpg"
                  >
                    {(props) => <Button {...props}>Upload image</Button>}
                  </FileButton>
                </Group>
              )}
            </Flex>
            <Flex direction={"column"} w={"40%"}>
              <Flex justify={"space-between"}>
                <TextInput
                  label="Phone Number"
                  w={"45%"}
                  placeholder="9999988888"
                  withAsterisk
                  readOnly={!isEditing}
                  {...form.getInputProps("phoneNo")}
                />
                <TextInput
                  label="Email"
                  w={"45%"}
                  withAsterisk
                  placeholder="John@gmail.com"
                  readOnly={!isEditing}
                  {...form.getInputProps("email")}
                />
              </Flex>
              <Flex justify={"space-between"}>
                <TextInput
                  label="Emergency Number"
                  w={"45%"}
                  placeholder="9999988888"
                  withAsterisk
                  readOnly={!isEditing}
                  {...form.getInputProps("emergencyNo")}
                />

                <Search
                  setSelected={setSelected}
                  address={address}
                  setAddress={setAddress}
                  readOnly={!isEditing}
                />
              </Flex>
              <TextInput
                label="Harbour Presentation"
                placeholder="Harbour Presentation"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("harbor")}
              />
            </Flex>
            <Flex align={"center"}>
              <Flex w={"100%"} h={"100%"}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={10}
                  center={selected ? selected : center}
                >
                  {selected && <Marker position={selected} />}
                </GoogleMap>
              </Flex>
            </Flex>
          </Flex>
          <Divider my="sm" />
          <Group grow align="flex-start" justify="space-around" px={24}>
            <Flex direction="column">
              <TextInput
                label="Name"
                placeholder="Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("name")}
              />
              <Select
                label="Type"
                withAsterisk
                placeholder="Club Type"
                data={CLUB_TYPE}
                clearable
                readOnly={!isEditing}
                {...form.getInputProps("clubType")}
              />
              <TextInput
                label="Fiscal company name of boat club"
                placeholder="Fiscal company name of boat club"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("companyName")}
              />
              <TextInput
                label="VAT number company"
                placeholder="VAT number company"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("vatNumber")}
              />
              <TextInput
                label="Address"
                withAsterisk
                placeholder="Address"
                readOnly={!isEditing}
                {...form.getInputProps("address1")}
              />
            </Flex>
            <Flex direction="column">
              <TextInput
                label="Zip Code"
                placeholder="Zip Code"
                withAsterisk
                hideControls
                readOnly={!isEditing}
                allowDecimal={false}
                {...form.getInputProps("zipCode")}
              />
              <TextInput
                label="City"
                placeholder="City"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("city")}
              />
              <Select
                label="Country"
                placeholder="Country"
                withAsterisk
                data={CountryList}
                searchable
                clearable
                readOnly={!isEditing}
                {...form.getInputProps("country")}
              />
              <DatePickerInput
                clearable
                leftSectionPointerEvents="none"
                withAsterisk
                label="High Season Start To End"
                placeholder=" DD/MM/YYYY - DD/MM/YYYY"
                type="range"
                readOnly={!isEditing}
                value={[
                  form.values.highStartDate
                    ? new Date(form.values.highStartDate)
                    : null,
                  form.values.highEndDate
                    ? new Date(form.values.highEndDate)
                    : null,
                ]}
                onChange={(value) => {
                  const [start, end] = value;
                  form.setFieldValue("highStartDate", start);
                  form.setFieldValue("highEndDate", end);
                }}
              />
              <DatePickerInput
                clearable
                leftSectionPointerEvents="none"
                label="Low Season Start To End"
                withAsterisk
                placeholder=" DD/MM/YYYY - DD/MM/YYYY"
                type="range"
                readOnly={!isEditing}
                value={[
                  form.values.lowStartDate
                    ? new Date(form.values.lowStartDate)
                    : null,
                  form.values.lowEndDate
                    ? new Date(form.values.lowEndDate)
                    : null,
                ]}
                onChange={(value) => {
                  const [start, end] = value;
                  form.setFieldValue("lowStartDate", start);
                  form.setFieldValue("lowEndDate", end);
                }}
              />
            </Flex>

            <Flex direction="column" justify="flex-start">
              <Flex
                direction={{
                  base: "column",
                  lg: "row",
                  xl: "row",
                }}
              >
                <Flex direction={"column"}>
                  <Select
                    label="High Season AM Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("highSeasonAM")}
                  />
                  <Select
                    label="Low Season AM Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("lowSeasonAM")}
                  />
                  <Select
                    label="Time Zone"
                    placeholder="Select Location"
                    withAsterisk
                    data={Timezones_List.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("timeZone")}
                  />

                  <NumberInput
                    label="Fuel Price"
                    placeholder="00"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("fuelPrice")}
                  />
                  <Select
                    label="VAT %"
                    placeholder="VAT %"
                    withAsterisk
                    data={Vat.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("vat")}
                  />
                  <NumberInput
                    label="Engine Hours Price"
                    placeholder="00"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("engineHourPrice")}
                  />
                </Flex>
                <Flex direction={"column"} ml={12}>
                  <Select
                    label="High Season PM Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("highSeasonPM")}
                  />
                  <Select
                    label="Low Season PM Hours"
                    withAsterisk
                    placeholder="00"
                    data={Hours.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("lowSeasonPM")}
                  />
                  <Select
                    label="Currency"
                    placeholder="Є"
                    withAsterisk
                    data={Currencies.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("currency")}
                  />
                  <Select
                    label="Fuel Units"
                    placeholder="Litre"
                    withAsterisk
                    data={FUEL_TYPE}
                    readOnly={!isEditing}
                    {...form.getInputProps("fuelType")}
                  />
                  <NumberInput
                    label="Electric Price"
                    placeholder="00 Є"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("electricPrice")}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Group>
        </Box>
      )}
    </Flex>
  );
};

const CenterTab = () => {
  return (
    <CenterTabContextProvider>
      <CenterTabContainer />
    </CenterTabContextProvider>
  );
};

export default CenterTab;

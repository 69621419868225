import {
  Flex,
  TextInput,
  Select,
  Button,
  MultiSelect,
  Box,
  Text,
  Loader,
} from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import { useState, useEffect } from "react";
import { USER_ROLE } from "shared/Constants/general.const";
import { useClubManagementContext } from "../../ClubManagement.context";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";

/**
 * Compares two arrays and returns true if they are equal
 *
 * @param {array} a first array to compare
 * @param {array} b second array to compare
 * @returns {boolean} true if the arrays are equal, false otherwise
 */
const arrayEquals = (a, b) => {
  // Check if both are arrays
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }
  // Check if they have the same length
  if (a.length !== b.length) {
    return false;
  }
  // Compare each element
  return a.every((val, index) => val === b[index]);
};

const arrayConvert = (arr) => (Array.isArray(arr) ? arr : [arr]);

const formatDate = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString)
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    .replace(",", "");
};

const ViewUser = ({
  userDetailsData,

  userDetailsClose,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const [loading, setLoading] = useState(true);
  const { userPermissions } = useUserContext();
  const {
    clubsList,
    regionsList,
    harborsList,
    updateRegionsList,
    updateHarborsList,
  } = useClubManagementContext();

  let userRoleData = [];
  if (userPermissions.roleName === USER_ROLE.MOXSEA_Support.value) {
    userRoleData = [
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubmanager.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubworker.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else {
    userRoleData = [
      {
        label: USER_ROLE.MOXSEA_Administrator.label,
        value: USER_ROLE.MOXSEA_Administrator.value,
      },
      {
        label: USER_ROLE.MOXSEA_Support.label,
        value: USER_ROLE.MOXSEA_Support.value,
      },
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  }

  const form = useForm({
    initialValues: {
      firstName: userDetailsData?.firstName || "",
      lastName: userDetailsData?.lastName || "",
      email: userDetailsData?.email || "",
      userRole: userDetailsData?.role || "",
      status: userDetailsData?.status || "",
      createdBy: userDetailsData?.createdBy || "",
      expiryDate: userDetailsData?.expiryDate || "",
      createdAt: userDetailsData?.createdAt || "",
      updatedAt: userDetailsData?.updatedAt || "",
      accessedClubs: userDetailsData?.accessedClubs || "",
      accessedRegions: userDetailsData?.accessedRegions || "",
      accessedHarbors: userDetailsData?.accessedHarbors || "",
    },
    validate: {
      firstName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      email: isEmail("Invalid email"),
      userRole: (value) => (!value ? "Please select User Role" : null),
      accessedClubs: (value, values) =>
        !value?.length &&
        (form.values.userRole === USER_ROLE.MOXSEA_Support.value ||
          form.values.userRole === USER_ROLE.Clubmanager.value ||
          form.values.userRole === USER_ROLE.Clubworker.value)
          ? "Club is required"
          : null,

      accessedRegions: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Region is required"
          : null,

      accessedHarbors: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Harbor is required"
          : null,
    },
  });

  const fetchAllUserData = async () => {
    if (userDetailsData) {
      // Set accessedclubs as either string or array based on user role
      let accessedClubs = userDetailsData.accessedClubs;

      if (
        userDetailsData.role === USER_ROLE.Clubmanager.value ||
        userDetailsData.role === USER_ROLE.Clubworker.value
      ) {
        accessedClubs = userDetailsData.accessedClubs[0];
      } else if (
        userDetailsData.role === USER_ROLE.MOXSEA_Administrator.value
      ) {
        accessedClubs = clubsList.map((club) => club.clubId);
      }

      // Set Form values
      form.setValues({
        firstName: userDetailsData.firstName || "",
        lastName: userDetailsData.lastName || "",
        email: userDetailsData.email || "",
        userRole: userDetailsData.role || "",
        accessedClubs: accessedClubs || "",
        accessedRegions: userDetailsData.accessedRegions || "",
        accessedHarbors: userDetailsData.accessedHarbors || "",
        status: userDetailsData.status || "",
        createdBy: userDetailsData.createdBy || "",
        expiryDate: userDetailsData.expiryDate || "",
        createdAt: userDetailsData.createdAt || "",
        updatedAt: userDetailsData.updatedAt || "",
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllUserData();
  }, [userDetailsData]);

  const handleAllRegionsChange = (selectedRegions) => {
    if (selectedRegions && selectedRegions.length > 0) {
      updateHarborsList(selectedRegions);
    }

    form.setFieldValue("accessedRegions", selectedRegions);
    form.setFieldValue("accessedHarbors", []);
  };

  const handleClubChange = (selectedClubId) => {
    if (form.values.userRole === USER_ROLE.Clubworker.value) {
      updateRegionsList(selectedClubId);
    }

    form.setFieldValue("accessedClubs", selectedClubId);
    form.setFieldValue("accessedRegions", []);
    form.setFieldValue("accessedHarbors", []);
  };

  const handleUserRoleChange = (selectedUserRole) => {
    form.setFieldValue("userRole", selectedUserRole);

    form.setFieldValue("accessedClubs", []);
    form.setFieldValue("accessedRegions", []);
    form.setFieldValue("accessedHarbors", []);
  };

  const formattedExpiryDate = formatDate(form.values.expiryDate);
  const formattedUpdatedAtDate = formatDate(form.values.updatedAt);
  const formattedCreatedAtDate = formatDate(form.values.createdAt);

  return (
    <Flex direction="column" p={8}>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <TextInput
            label={<span style={{ fontWeight: "bold" }}>First Name</span>}
            withAsterisk
            placeholder="First Name"
            readOnly={!isEditing}
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label={<span style={{ fontWeight: "bold" }}>Last Name</span>}
            withAsterisk
            placeholder="Last Name"
            readOnly={!isEditing}
            {...form.getInputProps("lastName")}
          />
          <TextInput
            label={<span style={{ fontWeight: "bold" }}>E-Mail</span>}
            withAsterisk
            readOnly={!isEditing}
            placeholder="Enter your email"
            {...form.getInputProps("email")}
          />
          <Select
            label={<span style={{ fontWeight: "bold" }}>User Role</span>}
            mt="md"
            placeholder="User Role"
            data={userRoleData}
            readOnly={!isEditing}
            {...form.getInputProps("userRole")}
            onChange={(value) => {
              handleUserRoleChange(value);
            }}
          />
          {form.values.userRole === USER_ROLE.MOXSEA_Support.value && (
            <Flex direction="column">
              <Flex align="center" mt="md">
                <MultiSelect
                  withAsterisk
                  w="110%"
                  readOnly={!isEditing}
                  hidePickedOptions
                  label={
                    <span style={{ fontWeight: "bold" }}>Accessed Clubs</span>
                  }
                  // placeholder="Choose Club(s)"
                  placeholder={
                    form.values.accessedClubs?.length > 0
                      ? ""
                      : "Choose Club(s)"
                  }
                  clearable
                  data={clubsList?.map((club) => ({
                    label: club?.clubName,
                    value: club?.clubId,
                  }))}
                  value={form.values.accessedClubs}
                  {...form.getInputProps("accessedClubs")}
                />
                <Flex mt="md" w="30%" justify="flex-end">
                  <Button
                    variant="subtle"
                    disabled={!isEditing}
                    onClick={() => {
                      form.setFieldValue(
                        "accessedClubs",
                        clubsList?.map((club) => club?.clubId)
                      );
                    }}
                  >
                    <Text td="underline"> Select All</Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubworker.value && (
            <Flex direction="column">
              <Select
                label={
                  <span style={{ fontWeight: "bold" }}>Accessed Club</span>
                }
                mt="md"
                placeholder={
                  form.values.accessedClubs?.length > 0 ? "" : "Choose Club(s)"
                }
                withAsterisk
                readOnly={!isEditing}
                clearable
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("accessedClubs")}
                onChange={(value) => handleClubChange(value)}
              />
              {form.values.accessedClubs && (
                <>
                  <Flex align="center" mt="md">
                    <MultiSelect
                      withAsterisk
                      readOnly={!isEditing}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Accessed Regions
                        </span>
                      }
                      hidePickedOptions
                      w="110%"
                      placeholder={
                        form.values.accessedRegions?.length > 0
                          ? ""
                          : "Choose Region(s)"
                      }
                      clearable
                      disabled={!form.values.accessedClubs}
                      data={regionsList?.map((region) => ({
                        label: region.regionName,
                        value: region.regionId,
                      }))}
                      {...form.getInputProps("accessedRegions")}
                      onChange={(value) => handleAllRegionsChange(value)}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        disabled={!isEditing}
                        onClick={() => {
                          const selectedRegionsIds = regionsList.map(
                            (region) => region?.regionId
                          );
                          form.setFieldValue(
                            "accessedRegions",
                            selectedRegionsIds
                          );
                          if (
                            !arrayEquals(
                              form.values.allRegions,
                              selectedRegionsIds
                            )
                          ) {
                            handleAllRegionsChange(selectedRegionsIds);
                          }
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex align="center" mt="md">
                    <MultiSelect
                      withAsterisk
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Accessed Harbors
                        </span>
                      }
                      hidePickedOptions
                      readOnly={!isEditing}
                      w="110%"
                      placeholder={
                        form.values.accessedHarbors?.length > 0
                          ? ""
                          : "Choose Harbor(s)"
                      }
                      clearable
                      disabled={!form.values.accessedRegions?.length}
                      data={harborsList?.map((harbor) => ({
                        label: harbor.harborName,
                        value: harbor.harborId,
                      }))}
                      {...form.getInputProps("accessedHarbors")}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        disabled={
                          !form.values.accessedRegions?.length || !isEditing
                        }
                        onClick={() => {
                          form.setFieldValue(
                            "accessedHarbors",
                            harborsList?.map((harbor) => harbor?.harborId)
                          );
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubmanager.value && (
            <Flex direction="column">
              <Select
                label={
                  <span style={{ fontWeight: "bold" }}>Accessed Club</span>
                }
                mt="md"
                placeholder={
                  form.values.accessedClubs?.length > 0 ? "" : "Choose Club(s)"
                }
                withAsterisk
                clearable
                readOnly={!isEditing}
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("accessedClubs")}
                onChange={(value) => handleClubChange(value)}
              />
            </Flex>
          )}
          <Text mt="md">
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                width: "150px",
              }}
            >
              Status -{" "}
            </span>
            {form.values.status}
          </Text>
          <Text mt="md">
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                width: "150px",
              }}
            >
              Created By -{" "}
            </span>
            {form.values.createdBy}
          </Text>
          <Text mt="md">
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                width: "150px",
              }}
            >
              Updated Date -{" "}
            </span>
            {formattedUpdatedAtDate}
          </Text>
          <Text mt="md">
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                width: "150px",
              }}
            >
              Created Date -{" "}
            </span>
            {formattedCreatedAtDate}
          </Text>
          <Text mt="md">
            <span
              style={{
                fontWeight: "bold",
                display: "inline-block",
                width: "150px",
              }}
            >
              Expiry Date -{" "}
            </span>
            {formattedExpiryDate}
          </Text>
          <Button
            mt={"md"}
            onClick={() => {
              userDetailsClose();
            }}
          >
            Close
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default ViewUser;

export const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const appUserId = "13c30678-246e-45d2-9da1-3885ab72ca3b";

export const ADMINISTRATOR = {
  CLUB_MANAGEMENT: "Club Management",
  USER_MANAGEMENT: "User Management",
}

export const FUEL_TYPE = ["Litre", "Gallon"]

export const BOAT_STATUS = ["Activated", "Deactivated"]

export const ENGINE_TYPE = ["Outboarder / Inboarder", "Electric", "Hybrid"]

export const CATEGORY_ORDER = [
  "Water Toys",
  "Catering",
  "Cleaning",
  "Refueling",
  "Other Services",
  "Captain",
];

export const ADDON_CATEGORIES = [
  {
    label: "Water Toys",
    value: "WATER_TOYS",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Catering",
    value: "CATERING",
  },
  {
    label: "Captain",
    value: "CAPTAIN",
  },
  {
    label: "Refueling",
    value: "REFUELING",
  },
  {
    label: "Other Services",
    value: "OTHER_SERVICES",
  },
];


export const ADDON_PRODUCT_TYPES = [
  { label: "Service", value: "Service" },
  { label: "Membership", value: "Membership" },
  { label: "Fuel", value: "Fuel" },
  { label: "Electricity", value: "Electricity" },
  { label: "ExtraDays", value: "ExtraDays" },
  { label: "EngineHours", value: "EngineHours" },
]


export const BOAT_CONDITIONS = [{ label: "PERFECT", value: "PERFECT" }, { label: "GOOD", value: "GOOD" }, { label: "OK", value: "OK" }, { label: "NO", value: "NO" }];

export const ALL_ISSUES = {
  mooringLines: "Mooring Lines",
  fenders: "Fenders",
  covers: "Covers",
  lowFreshWater: "Low Fresh Water",
  dirtiness: "Dirtiness",
  lowFuelVoltage: "Low Fuel Voltage",
  dayHead: "Day Head",
  somethingBroken: "Something Broken",
  coolerHvacSystem: "Cooler Hvac System",
  other: "Other",
};

export const CATEGORY_FILTERS = {
  WATER_TOYS: "Water Toys",
  CATERING: "Catering",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  REFUELING: "Refueling",
  CAPTAIN: "Captain",
};

export const CENTER_TABS = {
  CENTER: "Center",
  BOAT_FLEETS: "Boat Fleets",
  SERVICES: "Services",
  MEMBERSHIPS: "Memberships",
}

export const CLUB_TYPE = ["Boat Club",
  "Charter company",
  "Harbour",
  "Boat Manufacturer",
  "Other"]

export const ALL_SERVICE_NAMES = {
  CATERING: "Catering",
  CAPTAIN: "Captain",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  EXTRA_ORDER: "Extra Order",
  REFUELING: "Refueling",
  WATER_TOYS: "Water Toys",
};
export const STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  TRUE: "true",
  FALSE: "false",
};

export const MEMBERSHIP_TYPE = {
  GOLD: "Gold",
  SILVER: "Silver",
  PLATINUM: "Platinum",
}


export const S3_FOLDER_NAME = {
  DOCUMENTS: 'doc',
  CO_MEMBERS_DOCUMENTS: 'comembers_docs',
  PROFILE_IMAGES: 'user',
  CHECKIN_CHECKOUT_REPORT_ISSUE: 'issues',
  HARBORS_IMAGES: 'harbors',
  BOAT_IMAGES: 'boats',
  CLUB_LOGO: 'club',
  POSTS: 'posts'
};

export const USER_ROLE = {
  MOXSEA_Administrator: {
    value: "MOXSEA_Administrator",
    label: "MOXSEA Administrator",
  },
  MOXSEA_Support: {
    value: "MOXSEA_Support",
    label: "MOXSEA Support",
  },
  Clubmanager: {
    value: "Club_Manager",
    label: "Club Manager",
  },
  Clubworker: {
    value: "Club_Worker",
    label: "Club Worker",
  },
};


export const CountryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];


export const CountryListData = [
  { id: 1, name: "Afghanistan" },
  { id: 2, name: "Albania" },
  { id: 3, name: "Algeria" },
  { id: 4, name: "American Samoa" },
  { id: 5, name: "Andorra" },
  { id: 6, name: "Angola" },
  { id: 7, name: "Anguilla" },
  { id: 8, name: "Antarctica" },
  { id: 9, name: "Antigua and Barbuda" },
  { id: 10, name: "Argentina" },
  { id: 11, name: "Armenia" },
  { id: 12, name: "Aruba" },
  { id: 13, name: "Australia" },
  { id: 14, name: "Austria" },
  { id: 15, name: "Azerbaijan" },
  { id: 16, name: "Bahamas (the)" },
  { id: 17, name: "Bahrain" },
  { id: 18, name: "Bangladesh" },
  { id: 19, name: "Barbados" },
  { id: 20, name: "Belarus" },
  { id: 21, name: "Belgium" },
  { id: 22, name: "Belize" },
  { id: 23, name: "Benin" },
  { id: 24, name: "Bermuda" },
  { id: 25, name: "Bhutan" },
  { id: 26, name: "Bolivia (Plurinational State of)" },
  { id: 27, name: "Bonaire, Sint Eustatius and Saba" },
  { id: 28, name: "Bosnia and Herzegovina" },
  { id: 29, name: "Botswana" },
  { id: 30, name: "Bouvet Island" },
  { id: 31, name: "Brazil" },
  { id: 32, name: "British Indian Ocean Territory (the)" },
  { id: 33, name: "Brunei Darussalam" },
  { id: 34, name: "Bulgaria" },
  { id: 35, name: "Burkina Faso" },
  { id: 36, name: "Burundi" },
  { id: 37, name: "Cabo Verde" },
  { id: 38, name: "Cambodia" },
  { id: 39, name: "Cameroon" },
  { id: 40, name: "Canada" },
  { id: 41, name: "Cayman Islands (the)" },
  { id: 42, name: "Central African Republic (the)" },
  { id: 43, name: "Chad" },
  { id: 44, name: "Chile" },
  { id: 45, name: "China" },
  { id: 46, name: "Christmas Island" },
  { id: 47, name: "Cocos (Keeling) Islands (the)" },
  { id: 48, name: "Colombia" },
  { id: 49, name: "Comoros (the)" },
  { id: 50, name: "Congo (the Democratic Republic of the)" },
  { id: 51, name: "Congo (the)" },
  { id: 52, name: "Cook Islands (the)" },
  { id: 53, name: "Costa Rica" },
  { id: 54, name: "Croatia" },
  { id: 55, name: "Cuba" },
  { id: 56, name: "Curaçao" },
  { id: 57, name: "Cyprus" },
  { id: 58, name: "Czechia" },
  { id: 59, name: "Côte d'Ivoire" },
  { id: 60, name: "Denmark" },
  { id: 61, name: "Djibouti" },
  { id: 62, name: "Dominica" },
  { id: 63, name: "Dominican Republic (the)" },
  { id: 64, name: "Ecuador" },
  { id: 65, name: "Egypt" },
  { id: 66, name: "El Salvador" },
  { id: 67, name: "Equatorial Guinea" },
  { id: 68, name: "Eritrea" },
  { id: 69, name: "Estonia" },
  { id: 70, name: "Eswatini" },
  { id: 71, name: "Ethiopia" },
  { id: 72, name: "Falkland Islands (the) [Malvinas]" },
  { id: 73, name: "Faroe Islands (the)" },
  { id: 74, name: "Fiji" },
  { id: 75, name: "Finland" },
  { id: 76, name: "France" },
  { id: 77, name: "French Guiana" },
  { id: 78, name: "French Polynesia" },
  { id: 79, name: "French Southern Territories (the)" },
  { id: 80, name: "Gabon" },
  { id: 81, name: "Gambia (the)" },
  { id: 82, name: "Georgia" },
  { id: 83, name: "Germany" },
  { id: 84, name: "Ghana" },
  { id: 85, name: "Gibraltar" },
  { id: 86, name: "Greece" },
  { id: 87, name: "Greenland" },
  { id: 88, name: "Grenada" },
  { id: 89, name: "Guadeloupe" },
  { id: 90, name: "Guam" },
  { id: 91, name: "Guatemala" },
  { id: 92, name: "Guernsey" },
  { id: 93, name: "Guinea" },
  { id: 94, name: "Guinea-Bissau" },
  { id: 95, name: "Guyana" },
  { id: 96, name: "Haiti" },
  { id: 97, name: "Heard Island and McDonald Islands" },
  { id: 98, name: "Holy See (the)" },
  { id: 99, name: "Honduras" },
  { id: 100, name: "Hong Kong" },
  { id: 101, name: "Hungary" },
  { id: 102, name: "Iceland" },
  { id: 103, name: "India" },
  { id: 104, name: "Indonesia" },
  { id: 105, name: "Iran (Islamic Republic of)" },
  { id: 106, name: "Iraq" },
  { id: 107, name: "Ireland" },
  { id: 108, name: "Isle of Man" },
  { id: 109, name: "Israel" },
  { id: 110, name: "Italy" },
  { id: 111, name: "Jamaica" },
  { id: 112, name: "Japan" },
  { id: 113, name: "Jersey" },
  { id: 114, name: "Jordan" },
  { id: 115, name: "Kazakhstan" },
  { id: 116, name: "Kenya" },
  { id: 117, name: "Kiribati" },
  { id: 118, name: "Korea (the Democratic People's Republic of)" },
  { id: 119, name: "Korea (the Republic of)" },
  { id: 120, name: "Kuwait" },
  { id: 121, name: "Kyrgyzstan" },
  { id: 122, name: "Lao People's Democratic Republic (the)" },
  { id: 123, name: "Latvia" },
  { id: 124, name: "Lebanon" },
  { id: 125, name: "Lesotho" },
  { id: 126, name: "Liberia" },
  { id: 127, name: "Libya" },
  { id: 128, name: "Liechtenstein" },
  { id: 129, name: "Lithuania" },
  { id: 130, name: "Luxembourg" },
  { id: 131, name: "Macao" },
  { id: 132, name: "Madagascar" },
  { id: 133, name: "Malawi" },
  { id: 134, name: "Malaysia" },
  { id: 135, name: "Maldives" },
  { id: 136, name: "Mali" },
  { id: 137, name: "Malta" },
  { id: 138, name: "Marshall Islands (the)" },
  { id: 139, name: "Martinique" },
  { id: 140, name: "Mauritania" },
  { id: 141, name: "Mauritius" },
  { id: 142, name: "Mayotte" },
  { id: 143, name: "Mexico" },
  { id: 144, name: "Micronesia (Federated States of)" },
  { id: 145, name: "Moldova (the Republic of)" },
  { id: 146, name: "Monaco" },
  { id: 147, name: "Mongolia" },
  { id: 148, name: "Montenegro" },
  { id: 149, name: "Montserrat" },
  { id: 150, name: "Morocco" },
  { id: 151, name: "Mozambique" },
  { id: 152, name: "Myanmar" },
  { id: 153, name: "Namibia" },
  { id: 154, name: "Nauru" },
  { id: 155, name: "Nepal" },
  { id: 156, name: "Netherlands (the)" },
  { id: 157, name: "New Caledonia" },
  { id: 158, name: "New Zealand" },
  { id: 159, name: "Nicaragua" },
  { id: 160, name: "Niger (the)" },
  { id: 161, name: "Nigeria" },
  { id: 162, name: "Niue" },
  { id: 163, name: "Norfolk Island" },
  { id: 164, name: "Northern Mariana Islands (the)" },
  { id: 165, name: "Norway" },
  { id: 166, name: "Oman" },
  { id: 167, name: "Pakistan" },
  { id: 168, name: "Palau" },
  { id: 169, name: "Palestine, State of" },
  { id: 170, name: "Panama" },
  { id: 171, name: "Papua New Guinea" },
  { id: 172, name: "Paraguay" },
  { id: 173, name: "Peru" },
  { id: 174, name: "Philippines (the)" },
  { id: 175, name: "Pitcairn" },
  { id: 176, name: "Poland" },
  { id: 177, name: "Portugal" },
  { id: 178, name: "Puerto Rico" },
  { id: 179, name: "Qatar" },
  { id: 180, name: "Republic of North Macedonia" },
  { id: 181, name: "Romania" },
  { id: 182, name: "Russian Federation (the)" },
  { id: 183, name: "Rwanda" },
  { id: 184, name: "Réunion" },
  { id: 185, name: "Saint Barthélemy" },
  { id: 186, name: "Saint Helena, Ascension and Tristan da Cunha" },
  { id: 187, name: "Saint Kitts and Nevis" },
  { id: 188, name: "Saint Lucia" },
  { id: 189, name: "Saint Martin (French part)" },
  { id: 190, name: "Saint Pierre and Miquelon" },
  { id: 191, name: "Saint Vincent and the Grenadines" },
  { id: 192, name: "Samoa" },
  { id: 193, name: "San Marino" },
  { id: 194, name: "Sao Tome and Principe" },
  { id: 195, name: "Saudi Arabia" },
  { id: 196, name: "Senegal" },
  { id: 197, name: "Serbia" },
  { id: 198, name: "Seychelles" },
  { id: 199, name: "Sierra Leone" },
  { id: 200, name: "Singapore" },
  { id: 201, name: "Sint Maarten (Dutch part)" },
  { id: 202, name: "Slovakia" },
  { id: 203, name: "Slovenia" },
  { id: 204, name: "Solomon Islands" },
  { id: 205, name: "Somalia" },
  { id: 206, name: "South Africa" },
  { id: 207, name: "South Georgia and the South Sandwich Islands" },
  { id: 208, name: "South Sudan" },
  { id: 209, name: "Spain" },
  { id: 210, name: "Sri Lanka" },
  { id: 211, name: "Sudan (the)" },
  { id: 212, name: "Suriname" },
  { id: 213, name: "Svalbard and Jan Mayen" },
  { id: 214, name: "Sweden" },
  { id: 215, name: "Switzerland" },
  { id: 216, name: "Syrian Arab Republic" },
  { id: 217, name: "Taiwan" },
  { id: 218, name: "Tajikistan" },
  { id: 219, name: "Tanzania, United Republic of" },
  { id: 220, name: "Thailand" },
  { id: 221, name: "Timor-Leste" },
  { id: 222, name: "Togo" },
  { id: 223, name: "Tokelau" },
  { id: 224, name: "Tonga" },
  { id: 225, name: "Trinidad and Tobago" },
  { id: 226, name: "Tunisia" },
  { id: 227, name: "Turkey" },
  { id: 228, name: "Turkmenistan" },
  { id: 229, name: "Turks and Caicos Islands (the)" },
  { id: 230, name: "Tuvalu" },
  { id: 231, name: "Uganda" },
  { id: 232, name: "Ukraine" },
  { id: 233, name: "United Arab Emirates (the)" },
  { id: 234, name: "United Kingdom of Great Britain and Northern Ireland (the)" },
  { id: 235, name: "United States Minor Outlying Islands (the)" },
  { id: 236, name: "United States of America (the)" },
  { id: 237, name: "Uruguay" },
  { id: 238, name: "Uzbekistan" },
  { id: 239, name: "Vanuatu" },
  { id: 240, name: "Venezuela (Bolivarian Republic of)" },
  { id: 241, name: "Viet Nam" },
  { id: 242, name: "Virgin Islands (British)" },
  { id: 243, name: "Virgin Islands (U.S.)" },
  { id: 244, name: "Wallis and Futuna" },
  { id: 245, name: "Western Sahara" },
  { id: 246, name: "Yemen" },
  { id: 247, name: "Zambia" },
  { id: 248, name: "Zimbabwe" },
  { id: 249, name: "Åland Islands" },
];

export const Currencies = [
  { id: 1, name: "USD" },
  { id: 2, name: "EUR" },
  { id: 3, name: "JPY" },
  { id: 4, name: "GBP" },
  { id: 5, name: "AUD" },
  { id: 6, name: "CAD" },
  { id: 7, name: "CHF" },
  { id: 8, name: "CNY" },
  { id: 9, name: "SEK" },
  { id: 10, name: "NZD" },
  { id: 11, name: "SGD" },
  { id: 12, name: "HKD" },
  { id: 13, name: "NOK" },
  { id: 14, name: "KRW" },
  { id: 15, name: "MXN" },
  { id: 16, name: "INR" },
  { id: 17, name: "BRL" },
  { id: 18, name: "ZAR" },
  { id: 19, name: "RUB" },
  { id: 20, name: "SAR" },
];

export const Hours = [
  { id: 1, name: 0 },
  { id: 2, name: 1 },
  { id: 3, name: 2 },
  { id: 4, name: 3 },
  { id: 5, name: 4 },
  { id: 6, name: 5 },
  { id: 7, name: 6 },
  { id: 8, name: 7 },
  { id: 9, name: 8 },
  { id: 10, name: 9 },
  { id: 11, name: 10 },
  { id: 12, name: 11 },
  { id: 13, name: 12 },
  { id: 14, name: 13 },
  { id: 15, name: 14 },
  { id: 16, name: 15 },
  { id: 17, name: 16 },
  { id: 18, name: 17 },
  { id: 19, name: 18 },
  { id: 20, name: 19 },
  { id: 21, name: 20 },
  { id: 22, name: 21 },
  { id: 23, name: 22 },
  { id: 24, name: 23 },
];

export const Vat = [
  { id: 1, name: 0 },
  { id: 2, name: 1 },
  { id: 3, name: 2 },
  { id: 4, name: 3 },
  { id: 5, name: 4 },
  { id: 6, name: 5 },
  { id: 7, name: 6 },
  { id: 8, name: 7 },
  { id: 9, name: 8 },
  { id: 10, name: 9 },
  { id: 11, name: 10 },
  { id: 12, name: 11 },
  { id: 13, name: 12 },
  { id: 14, name: 13 },
  { id: 15, name: 14 },
  { id: 16, name: 15 },
  { id: 17, name: 16 },
  { id: 18, name: 17 },
  { id: 19, name: 18 },
  { id: 20, name: 19 },
  { id: 21, name: 20 },
  { id: 22, name: 21 },
  { id: 23, name: 22 },
  { id: 24, name: 23 },
  { id: 25, name: 24 },
  { id: 26, name: 25 },
  { id: 27, name: 26 },
  { id: 28, name: 27 },
  { id: 29, name: 28 },
  { id: 30, name: 29 },
  { id: 31, name: 30 },
  { id: 32, name: 31 },
  { id: 33, name: 32 },
  { id: 34, name: 33 },
  { id: 35, name: 34 },
  { id: 36, name: 35 },
  { id: 37, name: 36 },
  { id: 38, name: 37 },
  { id: 39, name: 38 },
  { id: 40, name: 39 },
  { id: 41, name: 40 },
  { id: 42, name: 41 },
  { id: 43, name: 42 },
  { id: 44, name: 43 },
  { id: 45, name: 44 },
  { id: 46, name: 45 },
  { id: 47, name: 46 },
  { id: 48, name: 47 },
  { id: 49, name: 48 },
  { id: 50, name: 49 },
  { id: 51, name: 50 },
  { id: 52, name: 51 },
  { id: 53, name: 52 },
  { id: 54, name: 53 },
  { id: 55, name: 54 },
  { id: 56, name: 55 },
  { id: 57, name: 56 },
  { id: 58, name: 57 },
  { id: 59, name: 58 },
  { id: 60, name: 59 },
  { id: 61, name: 60 },
  { id: 62, name: 61 },
  { id: 63, name: 62 },
  { id: 64, name: 63 },
  { id: 65, name: 64 },
  { id: 66, name: 65 },
  { id: 67, name: 66 },
  { id: 68, name: 67 },
  { id: 69, name: 68 },
  { id: 70, name: 69 },
  { id: 71, name: 70 },
  { id: 72, name: 71 },
  { id: 73, name: 72 },
  { id: 74, name: 73 },
  { id: 75, name: 74 },
  { id: 76, name: 75 },
  { id: 77, name: 76 },
  { id: 78, name: 77 },
  { id: 79, name: 78 },
  { id: 80, name: 79 },
  { id: 81, name: 80 },
  { id: 82, name: 81 },
  { id: 83, name: 82 },
  { id: 84, name: 83 },
  { id: 85, name: 84 },
  { id: 86, name: 85 },
  { id: 87, name: 86 },
  { id: 88, name: 87 },
  { id: 89, name: 88 },
  { id: 90, name: 89 },
  { id: 91, name: 90 },
  { id: 92, name: 91 },
  { id: 93, name: 92 },
  { id: 94, name: 93 },
  { id: 95, name: 94 },
  { id: 96, name: 95 },
  { id: 97, name: 96 },
  { id: 98, name: 97 },
  { id: 99, name: 98 },
  { id: 100, name: 99 },
  { id: 101, name: 100 },
];

export const Timezones_List = [
  {
    id: 1,
    name: -12,
  },
  {
    id: 2,
    name: -11,
  },
  {
    id: 3,
    name: -10,
  },
  {
    id: 4,
    name: -9,
  },
  {
    id: 5,
    name: -8,
  },
  {
    id: 6,
    name: -7,
  },
  {
    id: 7,
    name: -6,
  },
  {
    id: 8,
    name: -5,
  },
  {
    id: 9,
    name: -4,
  },
  {
    id: 10,
    name: -3,
  },
  {
    id: 11,
    name: -2,
  },
  {
    id: 12,
    name: -1,
  },
  {
    id: 13,
    name: 0,
  },
  {
    id: 14,
    name: +1,
  },
  {
    id: 15,
    name: +2,
  },
  {
    id: 16,
    name: +3,
  },
  {
    id: 17,
    name: +4,
  },
  {
    id: 18,
    name: +5,
  },
  {
    id: 19,
    name: +6,
  },
  {
    id: 20,
    name: +7,
  },
  {
    id: 21,
    name: +8,
  },
  {
    id: 22,
    name: +9,
  },
  {
    id: 23,
    name: +10,
  },
  {
    id: 24,
    name: +11,
  },
  {
    id: 25,
    name: +12,
  },
];

const GeneralConst = () => {
  return;
};

export default GeneralConst;

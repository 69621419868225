import { useMemo } from "react";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, ScrollArea, Title, Button, Modal, Loader } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import {
  UserManagementnContextProvider,
  useUserManagementContext,
} from "./UserManagement.context";

import AddUserDetails from "./Components/AddUserDetails";
import ViewUserDetails from "./Components/ViewUserDetails";
import { USER_ROLE } from "shared/Constants/general.const";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";

const UserManagementContainer = () => {
  const { userData, loading, getAllUserData } = useUserManagementContext();

  const { userPermissions } = useUserContext();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    userDetailsOpened,
    { open: userDetailsOpen, close: userDetailsclose },
  ] = useDisclosure(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        size: 150,
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "lastName",
        size: 150,
        header: "Last Name",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "clubOrMoxsea",
        size: 200,
        header: "Club/Moxsea",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "role",
        size: 200,
        header: "Role",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "Details",
        size: 200,
        header: "Details",
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              const userId = row.original.userId;
              // fetchUserDetails(userId);
              getAllUserData(userId);
              userDetailsOpen();
            }}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: userData,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: false,
    enableTopToolbar: true,
    getRowId: (row) => row.id,
  });

  return (
    <Flex direction="column">
      <ScrollArea>
        {loading ? (
          <Flex justify="center" align="center" h="70vh">
            <Loader color="blue" size="xl" />
          </Flex>
        ) : (
          <>
            <Flex mb={8}>
              <Title order={3}>Administration - User Management</Title>
              <Button
                leftSection={<IconPlus size={14} />}
                variant="default"
                ml={8}
                onClick={open}
                disabled={
                  userPermissions.roleName === USER_ROLE.Clubworker.value
                }
              >
                Add New
              </Button>
            </Flex>
            <Modal
              opened={opened}
              closeOnClickOutside={false}
              closeOnEscape={false}
              onClose={close}
              title={
                <span style={{ fontWeight: "bold" }}>Add User Details</span>
              }
              size="lg"
              overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
              }}
            >
              <AddUserDetails close={close} />
            </Modal>
            <MantineReactTable table={table} />
            <>
              <Modal
                opened={userDetailsOpened}
                onClose={userDetailsclose}
                title={
                  <span style={{ fontWeight: "bold" }}>View User Details</span>
                }
                size="lg"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
                }}
                closeOnClickOutside={false}
                closeOnEscape={false}
              >
                <ViewUserDetails closeHandler={userDetailsclose} />
              </Modal>
            </>
          </>
        )}
      </ScrollArea>
    </Flex>
  );
};

const ClubManagement = () => {
  return (
    <UserManagementnContextProvider>
      <UserManagementContainer />
    </UserManagementnContextProvider>
  );
};

export default ClubManagement;

import { createContext, useContext, useEffect, useState } from "react";
import { API_URLS } from "shared/Constants/api.const";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";
import http from "shared/utils/http";

const CenterTabContext = createContext({
  harbourDetailsEdit: {},
  harbourDetails: {},
  uploadImage: () => {},
  setHarborId: () => {},
  loading: true,
});

export const CenterTabContextProvider = ({ children }) => {
  const [harbourDetails, setHarbourDetails] = useState({});
  const [harborId, setHarborId] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchHarbourList = async () => {
    //API for Harbour Data
    if (harborId) {
      const response = await http
        .get(`${API_URLS.GET_CLUBS_HARBOUR}/${harborId}?includeChildData=true`)
        .then((data) => data)
        .catch((error) => {});

      setHarbourDetails(response?.portalHarborResponseModel || []);
      setLoading(false);
    }
  };

  const uploadImage = async (imageFile) => {
    //API for upload image
    setLoading(true);
    const folderName = S3_FOLDER_NAME.HARBORS_IMAGES;
    const formData = new FormData();
    formData.append("files", imageFile);
    let res;

    res = await http.postFormData(
      `${API_URLS.UPLOAD_IMAGE}${folderName}`,
      formData
    );

    setLoading(false);
    return res;
  };

  useEffect(() => {
    fetchHarbourList();
  }, [harborId]);

  //PUT API Call
  const harbourDetailsEdit = async (newharbourDetails, harborId) => {
    setLoading(true);

    const res = await http
      .put(`${API_URLS.EDIT_HARBOUR}/${harborId}`, newharbourDetails)
      .then((data) => {
        setLoading(false);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
    return res;
  };

  return (
    <CenterTabContext.Provider
      value={{
        harbourDetailsEdit,
        harbourDetails,
        setHarborId,
        uploadImage,
        loading,
      }}
    >
      {children}
    </CenterTabContext.Provider>
  );
};

export const useCenterTabContext = () => useContext(CenterTabContext);

import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";
import ClubManagement from "./Components/ClubManagement/ClubManagement";
import UserManagement from "./Components/UserManagement/UserManagement";
import { Container, Title } from "@mantine/core";
import ClubSetting from "./Components/ClubSetting";
const Administration = lazy(() => import("./Administration"));

const DummyMainComponent = () => (
  <Container display="flex" justify="center">
    <Title order={5} c="gray">
      Please select an option from the left pane to show component
    </Title>
  </Container>
);

const AdministrationRoutes = [
  {
    path: ROUTE_PATHS.ADMINISTRATION,
    element: (
      <Suspense>
        <Administration />
      </Suspense>
    ),
    children: [
      { path: "", element: <DummyMainComponent /> },
      { path: "club-management", element: <ClubManagement /> },
      { path: "user-management", element: <UserManagement /> },
      { path: "club-settings", element: <ClubSetting /> },
    ],
  },
];

export default AdministrationRoutes;

import Config from "../../env.json";

export const BASE_URL = Config.BASE_URL;

export const API_URLS = {
  LOGIN: "/portal-users/login",
  REFRESH_TOKEN: "/getRefreshedTokens",
  GET_USER_DETAILS: "/portal-users",
  GET_ALL_CLUBS: `${BASE_URL}/portal/clubs/internal`,
  // GET_CLUBS_BY_CURRENT_USER: `${BASE_URL}/portal/clubs/find-by-app-user`,
  GET_REGIONS_BY_CLUB: `${BASE_URL}/portal/clubs/regions/find-by-club?clubId=`,
  CREATE_REGION: `${BASE_URL}/portal/clubs/region`,
  UPDATE_REGION: `${BASE_URL}/portal/clubs/regions/:regionId`,
  GET_HARBOURS_BY_REGION: `${BASE_URL}/portal/clubs/harbors/find-by-region?regionIds=`,
  GET_BOATS_BY_HARBOUR: `${BASE_URL}/portal/clubs/boats/find-by-harbor`,
  GET_BOAT_LIST_DETAILED: `${BASE_URL}/portal/boats?includeChildData=true`,
  GET_BOAT_DETAILS_BY_ID: `${BASE_URL}/portal/clubs/boats/:boatId`,
  GET_BOAT_CATEGORIES: `${BASE_URL}/portal/clubs/boats/categories`,
  ADD_NEW_BOAT: `${BASE_URL}/portal/clubs/boats`,
  EDIT_BOAT: `${BASE_URL}/portal/clubs/boats/:boat-id`,
  GET_MEMBERS_BY_CLUB: `${BASE_URL}/portal/clubs/:clubId/members?query=EMPTY&limit`,
  // GET_MEMBERS_BY_CLUB: `${BASE_URL}/portal/clubs/members/:clubId/EMPTY?includeAllData=false&limit=100`,
  GET_BOOKINGS_BY_BOAT: `${BASE_URL}/portal/bookings/find-blocked-dates-for-boat`,
  GET_ADD_ONS_BY_BOAT: `${BASE_URL}/portal/clubs/services/find-by-boat`,
  GET_ADD_ONS_BY_HARBOUR: `${BASE_URL}/portal/clubs/harbors/services`,
  CHANGE_CHECKIN_BOOKINGS: `${BASE_URL}/portal/bookings/:bookingId/checkin/:bookingActionId`,
  CHANGE_CHECKOUT_BOOKINGS: `${BASE_URL}/portal/bookings/:bookingId/checkout/:bookingActionId`,
  PORTAL_BOOKINGS: `${BASE_URL}/portal/bookings`,
  BOOKING_DETAILS: `${BASE_URL}/portal/bookings/:bookingId/details`,
  GET_PORTAL_CLUBS: `${BASE_URL}/portal/clubs/internal`,
  CREATE_BOOKING: `${BASE_URL}/portal/bookings`,
  CHANGE_BOOKING: `${BASE_URL}/portal/bookings/:booking-id`,
  CANCEL_BOOKING: `${BASE_URL}/portal/bookings/:bookingId/cancelled`,
  GET_MEMBERSHIP_LIST: `${BASE_URL}/portal/clubs/membershipLevels/:clubId/:harborId`,
  ADD_MEMBERSHIPS: `${BASE_URL}/portal/clubs/membershipLevel`,
  EDIT_MEMBERSHIPS: `${BASE_URL}/portal/clubs/membershipLevel`,
  GET_BOATS_LIST: `${BASE_URL}/portal/boats?includeChildData=false`,
  GET_CLUBS_HARBOUR: `${BASE_URL}/portal/clubs/harbor`,
  ADD_HARBOUR: `${BASE_URL}/portal/clubs/AddHarbor`,
  EDIT_HARBOUR: `${BASE_URL}/portal/clubs/harbor`,
  BOAT_GOING_OUT: `${BASE_URL}/portal/bookings/todays-overview`,
  GET_CLIENTS_BY_CLUB: `${BASE_URL}/portal/app-users/:clubId?includeChildData=true`,
  ADD_CLIENT: `${BASE_URL}/portal/app-user`,
  SELECT_MEMBERSHIPS: `${BASE_URL}/portal/app-user/membership`,
  GET_SERVICES_LIST: `${BASE_URL}/portal/clubs/harbors/services`,
  GET_SERVICE_CATEGORIES_IDS: `${BASE_URL}/portal/clubs/serviceCategories`,
  ADD_SERVICES: `${BASE_URL}/portal/clubs/harbors/service`,
  EDIT_SERVICES: `${BASE_URL}/portal/clubs/harbors/services`,
  DELETE_SERVICES: `${BASE_URL}/portal/clubs/harbors/services`,
  FORGOT_PASSWORD: `${BASE_URL}/portal-user/forgot-password`,
  VERIFY_USER: `${BASE_URL}/portal-user/verify-user`,
  VERIFY_APP_USER: `${BASE_URL}/app-user/verify-user`,
  SAVE_PASSWORD: `${BASE_URL}/portal-user/save-password`,
  APP_USER_SAVE_PASSWORD: `${BASE_URL}/app-user/save-password`,
  USER_PERMISSIONS: `${BASE_URL}/portal-user/permissions`,
  USER_CLUBS_DETAILS: `${BASE_URL}/portal/clubs/details`,
  ISSUE_LIST_DETAILS: `${BASE_URL}/portal/clubs/:club-id/issues`,
  UPDATE_LIST_DETAILS: `${BASE_URL}/portal/clubs/issues/:issueId`,
  CLIENT_DETAILS: `${BASE_URL}/portal/app-user/client/:membershipId?includeChildData=true`,
  EDIT_CLIENT_DETAILS: `${BASE_URL}/portal/app-user/:membershipId`,
  EDIT_MEMBERSHIP_DETAILS: `${BASE_URL}/portal/app-user/membership/:membershipId`,
  USER_LIST: `${BASE_URL}/portal-users`,
  ADD_USER: `${BASE_URL}/portal-user`,
  GET_ALL_REGIONS: `${BASE_URL}/portal/clubs/regions`,
  GET_USER_LIST_DETAILS: `${BASE_URL}/portal-users/:user-id`,
  GET_HARBOURS_BY_REGION_IDS: `${BASE_URL}/portal/clubs/harbors/find-by-region`,
  GET_STRIPE_ACCOUNT_SESSION: `${BASE_URL}/stripe/account_session`,
  ADD_CLUB: `${BASE_URL}/portal/clubs/club`,
  CLUB_DETAILS: `${BASE_URL}/portal/clubs/:club-id`,
  EDIT_CLUB_DETAILS: `${BASE_URL}/portal/clubs/club/:clubId`,
  UPLOAD_IMAGE: `${BASE_URL}/files/upload?folderName=`,
  CLUB_ONBOARDING: `${BASE_URL}/portal/clubs/:clubId/onboard`,
  GET_MEMBER_RESOURCES: `${BASE_URL}/portal/clubs/:clubId/users/:userId/memberResources`,
  GET_PRODUCTS_LIST: `${BASE_URL}/clubs/products`,
  CREATE_INVOICE: `${BASE_URL}/stripe/invoice`,
  GET_INVOICES: `${BASE_URL}/stripe/club/invoices`,
  ACTIVATE_USER: `${BASE_URL}/portal/app-user/membership/:membershipId/activate`,
  POST_INVOICE_MEMBERSHIP: `${BASE_URL}/portal/app-user/membership/:membershipId`,
  CREATE_BOOKING_INVOICE: `${BASE_URL}/portal/bookings/:bookingId/invoice`,
  CREATE_WHATS_NEW_POST: `${BASE_URL}/portal/post`,
  GET_ALL_POSTS: `${BASE_URL}/portal/posts`,
  GET_SINGLE_POST: `${BASE_URL}/portal/posts`,
  UPDATE_WHATS_NEW_POST: `${BASE_URL}/portal/posts`,
  CLIENT_FORGET_PASSWORD: `${BASE_URL}/app-user/forgot-password`,
  CANCEL_MEMBERSHIP: `${BASE_URL}/clubs/app-user/memberships/:membershipId/cancel`,
  CO_MEMBER_LIST_BY_MEMBERSHIP_ID: `${BASE_URL}/portal/app-user/memberships/:membershipId/co-members?includeChildData=true`,
  CO_MEMBER_BY_CO_MEMBER_ID: `${BASE_URL}/portal/app-user/memberships/:membershipId/co-members/:co-memberId?includeChildData=true`,
  UPDATE_CO_MEMBER_DETAILS: `${BASE_URL}/portal/app-user/memberships/:membershipId/co-members/:co-memberId`,
  ADD_CO_MEMBER: `${BASE_URL}/portal/app-user/memberships/:membershipId/co-member`,
  DELETE_CO_MEMBER: `${BASE_URL}/portal/app-user/memberships/:membershipId/co-members/:co-memberId`,
  GET_NOTIFICATIONS_LIST: `${BASE_URL}/portal/{clubId}/custom-notifications`,
  DELETE_NOTIFICATION: `${BASE_URL}/app-users/{appUserId}/notifications/:notificationId`,
  SEND_CUSTOM_NOTIFICATION: `${BASE_URL}/portal/custom-notifications`,
  RESEND_NOTIFICATION: `${BASE_URL}/portal/resend-custom-notification/:customNotificationId`,
  DEACTIVATE_CLUB: `${BASE_URL}/portal/clubs/:clubId/deactivate`,
  ACTIVATE_CLUB: `${BASE_URL}/portal/clubs/:clubId/activate`,
  GET_NOTES_BY_USER_ID: `${BASE_URL}/portal/:userId/notes`,
  CREATE_NOTES: `${BASE_URL}/portal/:userId/add-notes`,
  UPDATE_NOTES: `${BASE_URL}/portal/:userId/notes/:noteId`,
  DELETE_NOTES: `${BASE_URL}/portal/:userId/notes/:noteId`,
};
